import React from 'react';

const LogoSvg = (props: { [key: string]: any }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1252.878 248.839" {...props}>
        <path d="M526.166 245.11l-44.088-58.783v58.783h-35.095V68.979h35.095v110.329l44.088-55.713h41.456l-47.378 57.906
            48.913 63.609h-42.991zM703.4 200.803c0 25.882-22.592 48.035-61.635 48.035-38.384 0-61.416-22.372-61.416-48.035
            0-16.67 9.213-33.12 27.418-39.92v-.439c-12.941-6.361-22.812-18.863-22.812-36.849 0-24.128 20.618-45.843 56.809-45.843
            36.412 0 56.81 21.715 56.81 45.623 0 18.206-10.09 30.708-22.811 37.069v.439c18.425 7.019 27.637 22.373 27.637
            39.92zm-87.956-3.289c0 11.625 9.432 22.372 26.321 22.372 17.328 0 26.761-10.747 26.761-22.372 0-12.064-9.871-22.374-26.761-22.374-16.67
            0-26.321 10.31-26.321 22.374zm26.321-48.255c15.135 0 24.129-9.651 24.129-21.057 0-10.528-8.774-21.057-24.129-21.057-15.134 0-23.908
            10.308-23.908 21.057 0 11.406 8.993 21.057 23.908 21.057zM722.489 184.353c0-38.824 25.883-63.829 54.178-63.829 16.451 0 30.269 8.335
            37.727 20.838V68.979h35.095V245.11h-29.612l-3.509-18.643c-7.896 12.721-21.934 21.714-39.262 21.714-28.296 0-54.617-25.005-54.617-63.828zm92.344
            0c0-18.865-12.063-32.244-28.296-32.244-16.45 0-28.733 13.819-28.733 32.244 0 18.643 12.283 32.243 28.733 32.243 16.232 0 28.296-13.38
            28.296-32.243zM872.088 184.791c0-38.823 26.541-64.266 53.959-64.266 18.205 0 32.023 9.212 39.919 21.715l3.509-18.645h29.612V245.11h-29.612l-3.509-18.643c-7.896
            12.721-21.714 21.714-39.919 21.714-27.418 0-53.959-25.443-53.959-63.39zm92.563-.438c0-18.865-12.064-32.244-28.296-32.244-16.67 0-28.953
            13.819-28.953 32.244 0 18.643 12.283 32.243 28.953 32.243 16.232 0 28.296-13.38 28.296-32.243zM1111.837 129.957v29.83c-8.555-5.923-23.25-10.748-37.068-10.748-10.968
            0-17.11 3.729-17.11 9.651 0 6.58 7.019 9.212 20.838 12.941 19.961 4.827 41.236 11.626 41.236 37.289 0 23.908-18.863 39.262-51.765 39.262-17.547
            0-32.681-4.387-43.43-11.406v-32.243c10.308 8.334 24.128 15.134 41.894 15.134 12.283 0 19.302-3.948 19.302-10.528 0-7.458-8.774-9.87-24.566-14.257-19.302-5.484-37.287-13.38-37.287-35.534
            0-22.372 18.205-38.823 49.57-38.823 14.258 0 28.953 3.509 38.386 9.432zM1177.425 135.44h.219c6.8-8.555 18.643-14.915 32.463-14.915 26.102 0
            42.772 15.574 42.772 43.43v81.156h-35.095v-71.285c0-14.915-6.361-21.715-19.302-21.715-11.846 0-21.057 7.458-21.057 20.398v72.603h-35.095V68.979h35.095v66.461zM185.602
            0C83.097 0 0 83.097 0 185.601c0 21.1 3.529 41.385 10.017 60.282h119.221l-1.448-2.272c-7.349-11.549-18.768-38.572-19.469-41.026-.023-.084-.038-.137-.038-.16v-4.322c0-.869.709-1.57
            1.578-1.57h69.202v-16.135H104.4a129.793 129.793 0 0014.872-29.81c.066-.191.136-.381.201-.573.12-.351.233-.705.35-1.057.149-.449.301-.898.445-1.349.035-.109.067-.219.102-.328.217-.687.431-1.375.637-2.066v-.008a129.219
            129.219 0 002.618-10.273h55.438V118.8h-34.388c-.139 0-.263-.018-.386-.064l.06-.135c-.019-.012-.041-.016-.06-.029 6.58-14.47 10.281-30.816 10.281-48.127
            0-.628-.008-1.257-.025-1.885.023-.006.051-.005.074-.011l-.001-.035H179.061V18.899l59.917 18.432-46.837 14.833v16.35H216.584v.035c.024.005.051.004.074.011a74.048
            74.048 0 00-.025 1.885c0 17.311 3.701 33.657 10.281 48.127-.018.012-.041.017-.06.029l.06.135a1.068 1.068 0 01-.386.064H192.14v16.133h55.438a129.219 129.219 0 002.618
            10.273v.008c.206.691.42 1.38.637 2.066.035.109.067.219.102.328.144.452.296.9.445 1.349.117.352.23.706.35 1.057.065.192.135.382.201.573a129.833 129.833 0 0014.872 29.81H192.14v16.135h69.2c.869
            0 1.578.701 1.578 1.57v4.322c0 .023-.015.076-.038.16-.701 2.455-12.12 29.478-19.469 41.026l-1.441 2.272h119.214c6.487-18.897 10.017-39.182 10.017-60.282C371.203 83.097 288.106 0 185.602 0z"
        />
    </svg>
);

export default LogoSvg;
